<template>
	<div>
		<a-layout>
			<a-layout-header>
				<div class="content flex space">
					<div class="flex alcenter ft24 text-w">
						海客云-管理中心
					</div>
					<div class="flex alcenter ft14 text-w">
						<span @click="pwdShow = true">欢迎您，{{ account_info.mobile }}</span> <a-button class="ml10" ghost @click="outLogin"><a-icon type="poweroff" /> 退出</a-button>
					</div>
				</div>
			</a-layout-header>
			<a-layout-content class="content" style="margin-top: 15px;">
				<div class="bg-w">
					<div class="pd30">
						<div class="flex space">
							<a-button type="primary" icon="plus" @click="addShow = true" v-if="is_create == 1 && my_max_num > mendian_count">添加</a-button>
							<div class="form-search-box ">
								<a-form layout="inline">
									<a-form-item label="关键字">
										<a-input v-model="keyword" placeholder="请输入关键字"></a-input>
									</a-form-item>
									<a-form-item>
										<a-button @click="searchAct" type="primary">搜索</a-button>
									</a-form-item>
								</a-form>
							</div>
							
						</div>
						<div class="mt20">
							<div class="wxb-table-gray">
								<a-table rowKey="staff_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :rowKey="list => list.key" :data-source="list"
								:loading="loading">
									<template slot="accountType" slot-scope="record,index">
										<a-tag color="purple" v-if="record.role_id == 1">创始人</a-tag>
										<a-tag color="blue" v-else>管理员</a-tag>
									</template>
									<template slot="action" slot-scope="record,index">
										<a-button @click="toLogin(record)">进入管理</a-button>
									</template>
								</a-table>
							</div>
						</div>
					</div>
				</div>
			</a-layout-content>
		</a-layout>
		<a-modal title="添加" :visible="addShow" width="400px" @ok="addAccount" @cancel="()=>{ addShow = false }">
			<a-input v-model="createData.name"  placeholder="请输入页面标题" />
		</a-modal>
		<a-modal title="修改密码" :visible="pwdShow" width="400px" @ok="editPwd" @cancel="()=>{ pwdShow = false }">
			<a-input v-model="pwdData.pwd"  placeholder="请输入新密码" />
			<a-input v-model="pwdData.repwd"  placeholder="请再次输入新密码" class="mt10" />
		</a-modal>
	</div>

</template>

<script>
	import { apiLayout, apiEditPwd, apiAccountList, apiCreateShop } from '../utils/js/api.js'
	import { message } from 'ant-design-vue'
	export default {
		data() {
			return {
				addShow:false,
				pwdShow:false,
				account_info:{},
				list:[],
				is_create:0,
				my_max_num:0,
				mendian_count:0,
				createData:{
					name:'',
					app_id:26
				},
				pwdData:{
					pwd:'',
					repwd:''
				},
				kefu:{
					mobile:'15544216868',
					name:'售前客服',
					qrcode:'https://img.d.xhmvip.com/37247ae7b54054260cf1abc7fe027a8d1709636299.jpg'
				},
				keyword:'',
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center',
						ellipsis: true
					},
					{
						title: '名称',
						dataIndex: 'name',
						align: 'center',
						ellipsis: true
					},
					{
						title: '创建时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
					{title: '类型',key: 'accountType',align: 'center',scopedSlots: {customRender: 'accountType'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		created(){
			apiLayout().then(res=>{
				this.account_info = res.account
				console.log('res',res.account)
			})
			this.getLists();
		},
		methods: {
			addAccount(){
				apiCreateShop(this.createData).then(res=>{
					let href=res.base_url + "auth?shop_token="+encodeURIComponent(res.shop_token);
					window.open(href);
				})
				this.addShow = false;
			},
			
			searchAct(){
				this.pagination.current = 1;
				this.getLists();
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				apiAccountList({
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					keyword:this.keyword,
				}).then(res=>{
					this.pagination.total=res.total;
					this.is_create = res.is_create;
					this.my_max_num = res.my_max_num;
					this.mendian_count = res.mendian_count;
					this.list=res.mendian;

					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			editPwd(){
				if(this.pwdData.pwd && this.pwdData.repwd){
					apiEditPwd(this.pwdData).then(res=>{
						console.log(res)
						message.success(res);
						this.pwdShow = false
						setTimeout(()=>{
							localStorage.removeItem('xhmmp-account-token');
							this.$router.push('/login');
						},1000)
					})
				}
				
			},
			outLogin(){
				localStorage.removeItem('xhmmp-account-token');
				this.$router.push('/login');
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			toLogin(record){
				let href=record.production_address + "auth?shop_token="+encodeURIComponent(record.shop_token);
				window.open(href);
			},
			
		}
	}
</script>

<style>
	.content{
		max-width: 1200px;
		margin: 0 auto;
	}
	
</style>